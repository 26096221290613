/**
 * Middleware that can be applied to page that require authentication using `definePageMeta({ middleware: ["check-auth"] });`
 *
 * It is also used for the /login route to redirect to /minside if the user is already authenticated.
 */
export default defineNuxtRouteMiddleware(async (to) => {
  const token = useCookie("__accessToken");

  if (!token.value && import.meta.client && to.path !== "/login") {
    return navigateToLogin(to);
  } else if (token.value && to.path === "/login") {
    return navigateTo("/minside");
  }
});
